import axios from 'axios'
import saver from 'file-saver'
import React, { ChangeEvent, FormEvent, useState } from 'react'
import './App.css'
import Uploader from './Uploader'

const App: React.FC = () => {
  const [file, setFile] = useState()
  const [licenseKey, setLicenseKey] = useState(localStorage.getItem('licenseKey') || '')
  const [shopName, setShopName] = useState(localStorage.getItem('company') || '')

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!file) {
      return
    }

    const data = new FormData()
    data.append('file', file)
    data.append('licenseKey', licenseKey)
    data.append('company', shopName)

    if (licenseKey) {
      localStorage.setItem('licenseKey', licenseKey)
    }

    localStorage.setItem('company', shopName || '')

    axios
      .post('https://pdf-klwxwjq6ta-ew.a.run.app/upload', data, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
        },
      })
      .then(res => {
        const blob = new Blob([res.data], {
          type: res.headers['content-type'],
        })
        saver.saveAs(blob, `etsy-${Date.now()}.pdf`)
      })
  }

  const handleChange = (fn: React.Dispatch<React.SetStateAction<string>>) => (e: ChangeEvent<HTMLInputElement>) => {
    const t = e.target
    fn(t.value || '')
  }
  const isEnabled = Boolean(file && (licenseKey && licenseKey.startsWith('ETSYKO') && licenseKey.length === 21))
  return (
    <div className='App'>
      <h1>Etsy Przelicznik</h1>
      <form onSubmit={handleSubmit}>
        <Uploader setFile={setFile} />
        <input
          name='license'
          value={licenseKey}
          placeholder='Nr licencji: ETSYKOxxxxxxxxxxxxxxx'
          onChange={handleChange(setLicenseKey)}
          className='license'
        />
        <br />
        <input
          name='shop'
          value={shopName}
          placeholder='Nazwa sklepu (opcjonalnie)'
          onChange={handleChange(setShopName)}
          className='license'
        />
        <br />
        <input className='submit' type='submit' value='Generuj PDF' disabled={!isEnabled} />
      </form>
    </div>
  )
}

export default App
