import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { ReactComponent as Icon } from './upload.svg'

function MyDropzone(props: { setFile: React.Dispatch<any> }) {
  const setFile = props.setFile
  const onDrop = useCallback(
    af => {
      if (af && af[0]) {
        setFile(af[0])
      }
    },
    [setFile],
  )
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ onDrop, accept: '.csv', multiple: false })

  const ac = (acceptedFiles && acceptedFiles[0] && acceptedFiles[0].name) || null

  return (
    <div {...getRootProps()} className={'upload' + (isDragActive ? ' active' : '') + (ac ? ' uploaded' : '')}>
      <Icon className='upload-icon' />
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Upuść plik…</p>
      ) : (
        <p>
          Przeciągnij plik CSV tutaj
          <br />
          albo kliknij aby go wybrać…
        </p>
      )}
      {ac && <div>Wybrany plik "{ac}"</div>}
    </div>
  )
}

export default MyDropzone
